<template>
  <div>
    <div
      class="hero-image mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="hero-text">
        <h1>What’s included in an Amchara retreat</h1>
      </div>
    </div>

    <featured-in></featured-in>

    <div class="body-content mx-auto">
      <!-- Personalised For You Section -->
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="green-header-2-text text-center">
              Your personalised retreat experience
            </div>
            <div class="row mt-5">
              <div class="col-md-7 mb-4 mb-md-0">
                <div class="row">
                  <div class="col-md-6">
                    <ul class="home-bullet-points-left">
                      <li class="p-list-item mb-3 mb-md-4">
                        A pre-arrival screening by our Doctor
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Your accommodation
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">Guided Walks</li>
                      <li class="p-list-item mb-3 mb-md-4">
                        FIRS infrared sauna
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Daily educational talks or food demonstrations
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Continual support from the naturopath &amp; health team
                        during your stay
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="home-bullet-points-right">
                      <li class="p-list-item mb-3 mb-md-4">
                        1:1 Health screening on arrival and exit
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Two daily yoga classes
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Two meditation classes per week
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Access to local gym
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        1:1 emotional wellbeing support
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Outdoor swimming pools
                      </li>
                      <li class="p-list-item mb-3 mb-md-4">
                        Scheduled ‘breaking of fast’ with healthy food
                      </li>
                    </ul>
                  </div>
                  <div class="col-12">
                    <li class="p-list-item">
                      For those fasting or cleansing we include... 4 freshly
                      made organic vegetable juices per day. These are all
                      interchangeable with soups, super smoothies, broths, fruit
                      or crudities if required/desired.
                    </li>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mt-4 mt-md-0 mb-3 mb-md-0">
                <img
                  width="100%"
                  src="@/assets/images/pages/whats-included/personalised-block.jpg"
                  alt="Personalised image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-9 mx-auto text-center">
            <p>
              For those who need to eat for health, medical or personal reasons
              we have the option of healthy, tailored meals (N.B. Tailored meals
              are chargeable £8 for breakfast, £12.50 for lunch and dinner.
              Whenever possible we always use organic ingredients. We prepare,
              raw, Palaeolithic and vegan meals all prepared freshly by our
              therapeutic chefs).
            </p>
          </div>
        </div>
        <div class="row mt-5 text-center">
          <div class="col-12 mx-auto">
            <h2 class="orange-text">
              Various additional facilities and exercise classes
            </h2>
            <h4 class="orange-text">
              (These vary between locations, time of year and therapist
              availability)
            </h4>

            <div class="row mt-5">
              <div class="col">
                <h2 class="text-primary">Somerset, UK</h2>
                <p>
                  Heated swimming pool May-September
                  <br />Weekly Tai-Chi classes
                </p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col">
                <h2 class="text-primary">Gozo, Malta</h2>
                <p>
                  Outdoor chlorine-free swimming pool
                  <br />Wellness garden including; fire-pit, jacuzzi, steam-room
                  and sauna <br />Aqua-Aerobics and other fitness classes
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5 text-center">
          <div class="col">
            <div class>
              <router-link
                to="/enquiries"
                class="btn btn-orange-gradient enquire-now-button"
                >ENQUIRE NOW</router-link
              >
            </div>
          </div>
        </div>
      </div>

      <hr width="80%" />

      <div class="container mt-5">
        <div class="row text-center">
          <div class="col-md-9 mx-auto">
            <h2 class="text-primary">Optional extras to enhance your stay</h2>
            <p>
              We have a range of optional extras available - all designed to
              enhance your stay and help you get the most from your time at our
              retreats. These include:
            </p>
          </div>
        </div>

        <div class="row mx-3 mx-md-5 pt-3 pb-2 text-center mt-4">
          <div class="col-md-12 mx-auto">
            <h3>A full range of health and spa treatments including:</h3>
          </div>
        </div>

        <div class="row text-center my-4 pt-4">
          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <h4 class="text-primary">Deep Tissue Massage</h4>
            <p>
              Enjoy a full body massage to melt away tensions and stress and to
              enhance the benefits of your detox
            </p>
            <h4 class="text-primary">£80</h4>
          </div>

          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <h4 class="text-primary">Colonic Hydrotherapy</h4>
            <p>
              Assisted colonics with an ARCH trained therapist. If you have IBS
              or other gut conditions then this is often a good option for you
            </p>
            <h4 class="text-primary">£85</h4>
          </div>

          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <h4 class="text-primary">Hopi Ear Candling</h4>
            <p>
              Ear candling is an ancient natural therapy used to cleanse,
              purify, heal the ears as well as relieve sinus issues
            </p>
            <h4 class="text-primary">£60</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <router-link
              to="/treatments"
              class="btn btn-green-gradient green-link-button m-4"
            >
              View our full
              <br class="d-block d-md-none" />treatments list
            </router-link>
          </div>
        </div>
      </div>

      <div class="container mt-5 mb-5">
        <div class="row mx-3 mx-md-5 pt-3 pb-2 text-center mt-4">
          <div class="col-md-12 mx-auto">
            <h3>Functional Medicine & Nutrition</h3>
          </div>
        </div>

        <div class="row text-center my-4 pt-4">
          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <h4 class="text-primary">Thyroflex Test</h4>
            <p>
              A non-invasive state of the art system to test, diagnose and
              manage thyroid conditions. Uses reflexes and symptoms to
              accurately determine Thyroid function.
            </p>
            <h4 class="text-primary">£60</h4>
          </div>

          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <h4 class="text-primary">Super Supplements</h4>
            <p>
              Our health team will create and prescribe a tailored super
              supplement programme to be followed during your retreat to
              maximise your body's ability to heal and recharge.
            </p>
            <h4 class="text-primary">£12 per day</h4>
          </div>

          <div class="col-12 col-md-4 mb-3 mb-md-0">
            <h4 class="text-primary">Tailored Meal Plans</h4>
            <p>
              A personalised meal plan that is tailored to provide you with
              optimum levels of nutrition during your stay. Meals include
              healthy breakfasts, raw food, healthy mains and snacks.
            </p>
            <h4 class="text-primary">From £8 per meal</h4>
          </div>
        </div>
      </div>

      <hr width="80%" />

      <trip-advisor></trip-advisor>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" /> -->

      <!-- 360 Degree Approach Section -->
      <!-- <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 mx-auto pb-lg-30">
            <div class="green-header-2-text text-center">360° approach</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mx-auto">
            <div class="row text-center">
              <div class="col-md-5 mx-auto mb-4">
                <img
                  width="100%"
                  src="@/assets/images/pages/whats-included/360-triangle.jpg"
                  alt="360 triangle image"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-9 mx-auto pb-lg-30 text-center">
            <p
              class
            >Amchara’s overall mission is to empower you to total health and your personalised health plan can create the foundation for good long-term health, excellent longevity and very low risk of disease.</p>
            <p
              class
            >We can support you to achieve your optimum health or ‘360 health’ by measuring your gut, hormone and genetic performance with a series of functional tests that help us to personalise your health in a detailed way.</p>
            <p
              class
            >These functional tests can be for individuals with a health problem or alternatively for those who are seeking optimal health.</p>
            <p
              class="darkgreen-text"
            >We offer a complimentary 15-minute mini-consultation to explore if this is a positive option for you.</p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto text-center">
            <a href="/360-health" class="btn btn-green-gradient green-link-button m-4">
              Find out more
              <br class="d-block d-md-none" />our 360° health approach
            </a>
          </div>
        </div>
      </div> -->

      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />
      <locations></locations>
      <div class="mt-3 mb-3">&nbsp;</div>
      <hr width="80%" />

      <testimonials></testimonials>

      <featured-mags></featured-mags>

      <div class="mt-3 mb-3">&nbsp;</div>
      <!--<hr width="80%" />
      <instagram></instagram>-->
    </div>

    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import FeaturedIn from "./page-partials/FeaturedIn";
import FeaturedMags from "./page-partials/FeaturedMags";
import Locations from "./page-partials/Locations";
import TripAdvisor from "./page-partials/TripAdvisor";
import Testimonials from "./page-partials/Testimonials";
import Instagram from "./page-partials/Instagram";
import NewsletterCta from "./page-partials/NewsletterCta";
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      bgImages: [
        require("@/assets/images/pages/whats-included/header-image.jpg"),
      ],
    };
  },
  components: {
    FeaturedIn,
    FeaturedMags,
    Locations,
    TripAdvisor,
    Testimonials,
    Instagram,
    NewsletterCta,
  },
};
</script>

<style>
</style>